/*global google: false, InfoBox: false, markerClusterer.MarkerClusterer: false */
/*jshint forin:true, noarg:true, noempty:true, eqeqeq:true, bitwise:true, strict:true, undef:true, unused:true, curly:true, browser:true, indent:2, jquery:true */
/*jshint devel: true */

'use strict';

$(document).ready(function(){


  
    /* MAPS */
    google.maps.visualRefresh = false;
    function initialize(idx, $container) {

        // min distance in meters
        var minDistance = 5;

        function getDistanceFromLatLonInMeters(lat1,lon1,lat2,lon2) {
          var R = 6371; // Radius of the earth in km
          var dLat = deg2rad(lat2-lat1);  // deg2rad below
          var dLon = deg2rad(lon2-lon1);
          var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *  Math.sin(dLon/2) * Math.sin(dLon/2);
          var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
          var d = (R * c) * 1000; // Distance in km
          return d;
        }

        function deg2rad(deg) {
          return deg * (Math.PI/180);
        }

        var data = $.parseJSON($container.find('div.data').attr('data-json'));

        // FIXME: change to class based finding, based on base class
        //create map
        var canvas = $container.find('div.map-canvas');
        if(canvas.length != 1) {
          return;
        }
        canvas = canvas[0];
        var map = new google.maps.Map(canvas, { zoom: 7, maxZoom: 17, mapTypeId: google.maps.MapTypeId.ROADMAP });
        google.maps.event.addListener(map, 'idle', function(){
          $('.gm-style').removeClass('gm-style');
        });
        var bounds = new google.maps.LatLngBounds();
        var markers = [];
        var infoBox = null;

        // create InfoBox
        infoBox = new InfoBox({
            content: 'holding...'
          });

        var ibOptions = {
            pixelOffset: new google.maps.Size(32, -40),
            closeBoxURL: '/img/gm_close.png',
            boxStyle: {
                opacity: 1,
                width: '200px'
              }
            };
        function uniqueCoordinates(newLat,newLng){
            if(!needsMoving(newLat,newLng)){
              return new google.maps.LatLng(newLat, newLng);
            } else {
              var newLngOrig=newLng;
              var newLatOrig=newLat;
              var moved = 0;
              function zeroLat(){
                newLat = newLatOrig;
              }
              function incLng(){
                newLng = newLngOrig + (moved*0.00005);
              }
              function decLng(){
                newLng = newLngOrig - (moved*0.00005);
              }
              function zeroLng(){
                newLng = newLngOrig;
              }
              function incLat(){
                newLat = newLatOrig + (moved*0.00005);
              }
              
              function decLat(){
                newLat = newLatOrig - (moved*0.00005);
              }
              var moves = [incLng,incLat,zeroLng,decLng,zeroLat, decLat, zeroLng];
              var loopCount=0;
              do{
                moves[moved%moves.length]();
                loopCount++;
                moved=Math.floor(loopCount/moves.length);
                // newLat = newLat+0.00005;
              } while (needsMoving(newLat,newLng) && loopCount < moves.length*20);
              if(moved == moves.length*20){
                // moved to much, reset 
                newLng = newLngOrig;
                newLat = newLatOrig;
              }
              return new google.maps.LatLng(newLat, newLng);
            }
          }
        function needsMoving(newLat,newLng){
          for (var i = 0; i < markers.length; i++) {
            var m = markers[i];
            var lat = m.getPosition().lat();
            var lng = m.getPosition().lng();
            var distance = getDistanceFromLatLonInMeters(newLat,newLng,lat,lng);
            if (distance < minDistance) {
              return true;
            }
          }
          return false;
        }


        function placeMarker(element){
            var marker_shadow = new google.maps.MarkerImage('/img/marker_shadow.png',new google.maps.Size(52.0, 44.0), new google.maps.Point(0, 0), new google.maps.Point(14.0, 22.0));
            var marker_icon = new google.maps.MarkerImage(element.markerImageName,new google.maps.Size(29.0, 44.0), new google.maps.Point(0, 0),new google.maps.Point(14.0, 22.0));

            var newLng = element.longitude;
            var newLat = element.latitude;
            var position = uniqueCoordinates(newLat,newLng);
            bounds.extend(position);
            var marker = new google.maps.Marker({
                position: position,
                icon: marker_icon,
                map: map,
                animation: google.maps.Animation.DROP,
                title: element.name,
                shadow : marker_shadow
              });
            markers.push(marker);
            google.maps.event.addListener(marker, 'click', function () {
                if(!element.infoHtml){
                  if(element.infoHtmlAjax){
                    var that = this;
                    $.get({ 'url': element.infoHtmlAjax, 'success': function( data){
                      element.infoHtml = data;
                      infoBox.setContent(element.infoHtml);
                      infoBox.setOptions(ibOptions);
                      infoBox.open(map, that);
                    } });
                  }
                } else {
                  infoBox.setContent(element.infoHtml);
                  infoBox.setOptions(ibOptions);
                  infoBox.open(map, this);
                }
              });
          }
        // gen. Locations
        if( data.locations){
          $.each(data.locations, function(index, value){
            placeMarker(value);
          });
        }
        if(data.persons){
          $.each(data.persons, function(index, value){
            placeMarker(value);
          });
        }

        map.fitBounds(bounds);

        var clusterStyles = [
          {
            opt_textColor: 'black',
            url: '/img/marker_multi.png',
            height: 70,
            width: 70,
            //anchor : new Array(25,32),
          },
          {
            opt_textColor: 'black',
            url: '/img/marker_multi.png',
            height: 70,
            width: 70,
            //anchor : new Array(25,29),
          },
          {
            opt_textColor: 'black',
            url: '/img/marker_multi.png',
            height: 70,
            width: 70,
            //anchor : new Array(25,26),
          }
        ];
        
        var mcOptions = {
          styles: clusterStyles,
          maxZoom: 15
        };

        console.log(map, markers);
        var markerCluster = new markerClusterer.MarkerClusterer({map, markers});
      }


    // call map
    $('.gmap-container-extended').each(function(i,elem) {
      initialize(i, $(elem));
    });
    /* legacy
    if($('#map--canvas').length > 0){
      initialize();
    }
    */
  });

$(document).ready(function(){
    var create_marker_icon = function( markerImage ) {
      // FIXME random?!
      return new google.maps.MarkerImage(markerImage,new google.maps.Size(29.0, 44.0),new google.maps.Point(0, 0),new google.maps.Point(14.0, 22.0));
    };

    var create_marker_shadow = function() {
      return new google.maps.MarkerImage('/img/marker_shadow.png', new google.maps.Size(52.0, 44.0), new google.maps.Point(0, 0), new google.maps.Point(14.0, 22.0));
    };

    $('.gmap-container-standard').each(function(i,elem) {
      var data = $(this).find('.data').data('json');
      // var center = new google.maps.LatLng(latLng[0],latLng[1]);
      var bounds = new google.maps.LatLngBounds();
      var canvas = $(elem).find('div.map-canvas');
      var map = new google.maps.Map(canvas, { maxZoom: 15, mapTypeId: google.maps.MapTypeId.ROADMAP});
      $.each(data.locations,function(i,el){
        var position =new google.maps.LatLng(el.latitude, el.longitude);
        bounds.extend(position);
        new google.maps.Marker({
          position: position,
          map: map,
          icon: create_marker_icon(el.markerImageName),
          shadow: create_marker_shadow(),
          animation: google.maps.Animation.DROP
        });
      });
      map.fitBounds(bounds);
    });
  });

